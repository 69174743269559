<template>
    <div class="profile">
        <div class="profile__block">
            <div class="profile__block-top">
                <h3 class="profile__title">
                    Личные данные
                </h3>
                <router-link :to="{ name: 'EditPersonalData' }" class="profile__block-edit"></router-link>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Телефон
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.phone | mask('+# ### #######') }}
                    </p>
                </div>
                <div v-if="user.contactData.email">
                    <p class="profile__block-title">
                        e-mail
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.email }}
                    </p>
                </div>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Фамилия
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.lastname || '-' }}
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Имя
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.firstname || '-' }}
                    </p>
                </div>
                <div v-if="user.contactData.patronymic">
                    <p class="profile__block-title">
                        Отчество
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.patronymic }}
                    </p>
                </div>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Дата рождения
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.birthday || '-' }}
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Пол
                    </p>
                    <p class="profile__block-value">
                        {{ gender }}
                    </p>
                </div>
            </div>
        </div>
        <div class="profile__block">
            <div class="profile__block-top">
                <h3 class="profile__title">
                    Паспортные данные
                </h3>
                <router-link :to="{ name: 'EditPassport' }" class="profile__block-edit"></router-link>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Серия
                    </p>
                    <p class="profile__block-value">
                        {{ user.passportData.passportseries }}
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Номер
                    </p>
                    <p class="profile__block-value">
                        {{ user.passportData.passportnumber }}
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Код подразделения
                    </p>
                    <p class="profile__block-value">
                        {{ user.passportData.passportissuecode }}
                    </p>
                </div>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Адрес регистрации
                    </p>
                    <p class="profile__block-value">
                        {{ user.contactData.addrcity }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="false" class="profile__block">
            <div class="profile__block-top">
                <h3 class="profile__title">
                    Привязанная карта
                </h3>
                <span class="profile__block-edit"></span>
            </div>
            <div class="profile__block-info">
                <div>
                    <p class="profile__block-title">
                        Номер карты
                    </p>
                    <p class="profile__block-value">
                        1
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Владелец
                    </p>
                    <p class="profile__block-value">
                        1
                    </p>
                </div>
                <div>
                    <p class="profile__block-title">
                        Срок
                    </p>
                    <p class="profile__block-value">
                        1
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './profile.scss'
import { mapGetters } from 'vuex'
import mask from '@index/helpers/string/setMask';

export default {
    name: 'Profile',
    computed: {
        ...mapGetters({
            user: 'application/user',
        }),
        gender() {
            const gender = this.user.contactData.gender

            if (gender === null || gender === undefined) {
                return '-'
            }

            return gender === 0 ? 'Мужской' : 'Женский'
        }
    },
    filters: {
        mask
    }
}
</script>